import { Checkbox, FormControlLabel } from '@material-ui/core'
import React from 'react'
import './MaterialCheckBox.scss'

export const MaterialCheckBox = (props) => {
    const { value, label, onChange, disabled } = props

    return (
        <div className="check-box-wrapper">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={onChange}
                        disabled={disabled}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }
                label={label}
            />
        </div>
    )
}
export default MaterialCheckBox
