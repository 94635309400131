import React from 'react'
import ImagePicker from 'components/elements/ImagePicker/ImagePicker'
import Input from 'components/elements/Input/Input'
import './ContentModalClub.scss'

export default function ModalContent(props) {
    const { formik } = props
    return (
        <div className="content-modalClub-wrapper">
            <div className="inputs-wrapper">
                <div className="input-wrapper">
                    <ImagePicker id="image" formik={formik} center subtitle="Foto de perfil" />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="email"
                        formik={formik}
                        type="text"
                        label="Email Admin"
                        placeholder="Email del administrador"
                    />
                </div>
                <div className="input-wrapper">
                    <Input id="name" formik={formik} type="text" label="Nombre" placeholder="Nombre" />
                </div>
                <div className="input-wrapper">
                    <Input id="zone" formik={formik} type="text" label="Zona" placeholder="Introduce la zona" />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="direction"
                        formik={formik}
                        type="text"
                        label="Dirección"
                        placeholder="Introduce la dirección"
                    />
                </div>
                <div className="row">
                    <div className="input-wrapper">
                        <Input id="city" formik={formik} type="text" label="Ciudad" placeholder="Ciudad" />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="zipCode"
                            formik={formik}
                            type="text"
                            label="Código Postal"
                            placeholder="Código Postal"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="input-wrapper">
                        <Input id="latitude" formik={formik} type="text" label="Latitud" placeholder="Latitud" />
                    </div>
                    <div className="input-wrapper">
                        <Input id="longitude" formik={formik} type="text" label="Longitud" placeholder="Longitud" />
                    </div>
                </div>
                <div className="input-wrapper">
                    <Input id="maxPeople" formik={formik} type="text" label="Aforo máximo" placeholder="Aforo máximo" />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="description"
                        formik={formik}
                        type="textarea"
                        label="Descripción"
                        placeholder="Introduce una descripción para tu evento"
                    />
                </div>

                <div className="row timetable-day">
                    <Input
                        id="openingTime_monday_close"
                        formik={formik}
                        type="checkbox"
                        label="Lunes"
                        innerLabel="Cerrado"
                    />
                    {!formik.values['openingTime_monday_close'] && (
                        <>
                            <Input
                                id="openingTime_monday_openTime"
                                formik={formik}
                                type="time"
                                label="Abre a las"
                                placeholder="23:00"
                            />
                            <Input
                                id="openingTime_monday_closeTime"
                                formik={formik}
                                type="time"
                                label="Cierra a las"
                                placeholder="06:30"
                            />
                        </>
                    )}
                </div>

                <div className="row timetable-day">
                    <Input
                        id="openingTime_tuesday_open"
                        formik={formik}
                        type="checkbox"
                        label="Martes"
                        innerLabel="Cerrado"
                    />
                    {!formik.values['openingTime_tuesday_open'] && (
                        <>
                            <Input
                                id="openingTime_tuesday_openTime"
                                formik={formik}
                                type="time"
                                label="Abre a las"
                                placeholder="23:00"
                            />
                            <Input
                                id="openingTime_tuesday_closeTime"
                                formik={formik}
                                type="time"
                                label="Cierra a las"
                                placeholder="06:30"
                            />
                        </>
                    )}
                </div>

                <div className="row timetable-day">
                    <Input
                        id="openingTime_wednesday_open"
                        formik={formik}
                        type="checkbox"
                        label="Miércoles"
                        innerLabel="Cerrado"
                    />
                    {!formik.values['openingTime_wednesday_open'] && (
                        <>
                            <Input
                                id="openingTime_wednesday_openTime"
                                formik={formik}
                                type="time"
                                label="Abre a las"
                                placeholder="23:00"
                            />
                            <Input
                                id="openingTime_wednesday_closeTime"
                                formik={formik}
                                type="time"
                                label="Cierra a las"
                                placeholder="06:30"
                            />
                        </>
                    )}
                </div>

                <div className="row timetable-day">
                    <Input
                        id="openingTime_thursday_open"
                        formik={formik}
                        type="checkbox"
                        label="Jueves"
                        innerLabel="Cerrado"
                    />
                    {!formik.values['openingTime_thursday_open'] && (
                        <>
                            <Input
                                id="openingTime_thursday_openTime"
                                formik={formik}
                                type="time"
                                label="Abre a las"
                                placeholder="23:00"
                            />
                            <Input
                                id="openingTime_thursday_closeTime"
                                formik={formik}
                                type="time"
                                label="Cierra a las"
                                placeholder="06:30"
                            />
                        </>
                    )}
                </div>

                <div className="row timetable-day">
                    <Input
                        id="openingTime_friday_open"
                        formik={formik}
                        type="checkbox"
                        label="Viernes"
                        innerLabel="Cerrado"
                    />
                    {!formik.values['openingTime_friday_open'] && (
                        <>
                            <Input
                                id="openingTime_friday_openTime"
                                formik={formik}
                                type="time"
                                label="Abre a las"
                                placeholder="23:00"
                            />
                            <Input
                                id="openingTime_friday_closeTime"
                                formik={formik}
                                type="time"
                                label="Cierra a las"
                                placeholder="06:30"
                            />
                        </>
                    )}
                </div>

                <div className="row timetable-day">
                    <Input
                        id="openingTime_saturday_open"
                        formik={formik}
                        type="checkbox"
                        label="Sábado"
                        innerLabel="Cerrado"
                    />
                    {!formik.values['openingTime_saturday_open'] && (
                        <>
                            <Input
                                id="openingTime_saturday_openTime"
                                formik={formik}
                                type="time"
                                label="Abre a las"
                                placeholder="23:00"
                            />
                            <Input
                                id="openingTime_saturday_closeTime"
                                formik={formik}
                                type="time"
                                label="Cierra a las"
                                placeholder="06:30"
                            />
                        </>
                    )}
                </div>

                <div className="row timetable-day">
                    <Input
                        id="openingTime_sunday_open"
                        formik={formik}
                        type="checkbox"
                        label="Domingo"
                        innerLabel="Cerrado"
                    />
                    {!formik.values['openingTime_sunday_open'] && (
                        <>
                            <Input
                                id="openingTime_sunday_openTime"
                                formik={formik}
                                type="time"
                                label="Abre a las"
                                placeholder="23:00"
                            />
                            <Input
                                id="openingTime_sunday_closeTime"
                                formik={formik}
                                type="time"
                                label="Cierra a las"
                                placeholder="06:30"
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
