import Button from 'components/elements/Button/Button'
import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader'
import ReactModal from 'components/elements/ReactModal/ReactModal'
import React, { useState, useEffect } from 'react'
import ModalContent from './ContentModalClub/ContentModalClub'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const ModalClubsAdmin = (props) => {
    const { editMode, loading, handleCloseModal, modalHandler, handleSubmit, club, edit } = props

    const clubPhoto = club?.image

    const formik = useFormik({
        initialValues: {
            email: (club && club.email) || '',
            name: (club && club.name) || '',
            image: (club && club.image) || '',
            description: (club && club.description) || '',
            direction: (club && club.location?.address) || '',
            zone: (club && club.location?.zone) || '',
            city: (club && club.location?.city) || '',
            zipCode: (club && club.location?.postalCode) || '',
            latitude: (club && club.location?.latitude) || '',
            longitude: (club && club.location?.longitude) || '',
            maxPeople: (club && club.maxPeople) || '',

            openingTime_monday_close: false,
            openingTime_monday_openTime: '',
            openingTime_monday_closeTime: '',

            openingTime_tuesday_open: false,
            openingTime_tuesday_openTime: '',
            openingTime_tuesday_closeTime: '',

            openingTime_wednesday_close: false,
            openingTime_wednesday_openTime: '',
            openingTime_wednesday_closeTime: '',

            openingTime_thursday_close: false,
            openingTime_thursday_openTime: '',
            openingTime_thursday_closeTime: '',

            openingTime_friday_close: false,
            openingTime_friday_openTime: '',
            openingTime_friday_closeTime: '',

            openingTime_saturday_close: false,
            openingTime_saturday_openTime: '',
            openingTime_saturday_closeTime: '',

            openingTime_sunday_close: false,
            openingTime_sunday_openTime: '',
            openingTime_sunday_closeTime: '',
        },
        validationSchema: Yup.object().shape({
            email: !editMode && Yup.string().required('Obligatorio'),
            name: Yup.string().required('Obligatorio'),
            description: Yup.string().max(250, 'máximo de 250 caracteres').required('Obligatorio'),
            image: !clubPhoto && Yup.object().required('Obligatorio'),
            zone: Yup.string().required('Obligatorio'),
            maxPeople: Yup.number().required('Obligatorio'),
            direction: Yup.string().required('Obligatorio'),
            city: Yup.string().required('Obligatorio'),
            zipCode: Yup.string().required('Obligatorio'),
            latitude: Yup.string().required('Obligatorio'),
            longitude: Yup.string().required('Obligatorio'),
        }),
        onSubmit: (values) => {
            editMode ? edit(values, club.idClub) : handleSubmit(values)
        },
    })

    return (
        <ReactModal
            open={modalHandler}
            close={() => {
                handleCloseModal()
                formik.resetForm()
            }}
            header={
                <ContainerHeader
                    title={editMode ? 'Editar discoteca' : 'Añadir discoteca'}
                    onClick={() => {
                        handleCloseModal()
                        formik.resetForm()
                    }}
                    modal
                />
            }
            footer={
                <div className="button-wrapper">
                    <Button loading={loading} type="submit" onClick={formik.handleSubmit}>
                        {editMode ? 'Guardar cambios' : 'Añadir discoteca'}
                    </Button>
                </div>
            }
        >
            <div className="admin-modal-wrapper">
                <ModalContent formik={formik} />
            </div>
        </ReactModal>
    )
}
export default ModalClubsAdmin
